import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncate",
})
export class TruncatePipePipe implements PipeTransform {
  transform(value: string, limit = 20, trail = "..."): string {
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
