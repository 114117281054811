import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ApiService } from "src/app/modules/shared/services/api.service";
import { GlobalStorageService } from "src/app/modules/shared/services/globalstorage.service";
import { IRevisionResponse } from "src/app/modules/application-data/data-conversion-v2/utility/interfaces";
import { TRevisionApiAddon } from "src/app/modules/application-data/data-conversion-v2/utility/types";
import { formApiHeader } from "src/app/modules/shared/utilities/utils";

@Injectable({
  providedIn: "root",
})
export class RevisionsApiService {
  constructor(
    private _apiService: ApiService,
    private _globalStorageService: GlobalStorageService
  ) {}

  getHistory(
    type: TRevisionApiAddon,
    typeId: string
  ): Promise<Array<IRevisionResponse>> {
    return firstValueFrom(
      this._apiService.get<Array<IRevisionResponse>>(
        `api/${type}/${typeId}/history`,
        formApiHeader({
          "x-application-id": this._globalStorageService.applicationId,
        })
      )
    );
  }

  getRevision<Response>(
    type: TRevisionApiAddon,
    typeId: string,
    revisionNumber: string
  ): Promise<Response> {
    return firstValueFrom(
      this._apiService.get<Response>(
        `api/${type}/${typeId}/revision/${revisionNumber}`,
        formApiHeader({
          "x-application-id": this._globalStorageService.applicationId,
        })
      )
    );
  }
}
