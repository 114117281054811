import { HttpHeaders, HttpParams } from "@angular/common/http";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TJsonObject, TPlatformTheme, TSplitterType } from "./types";
import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { IApiCallHeaders } from "./interfaces";

// compare both objects and only keep the changed properties
export function keepChanged<T extends TJsonObject>(
  originalObj: T,
  newObj: T
): T {
  return Object.keys(newObj).reduce((acc: TJsonObject, key: string) => {
    if (originalObj[key] !== newObj[key]) {
      acc[key] = newObj[key];
    }
    return acc;
  }, {}) as T;
}

export function binarySearchAnything<Type extends string | number>(
  sortedArray: Array<Type>,
  key: Type extends string ? string : number
): number {
  let start = 0;
  let end = sortedArray.length - 1;

  while (start <= end) {
    const middle = Math.floor((start + end) / 2);

    if (sortedArray[middle] === key) {
      return middle;
    } else if (sortedArray[middle] < key) {
      start = middle + 1;
    } else {
      end = middle - 1;
    }
  }
  return -1;
}

export function customPaginator(): MatPaginatorIntl {
  const customPaginatorIntl = new MatPaginatorIntl();
  customPaginatorIntl.itemsPerPageLabel = "Shown per page";
  customPaginatorIntl.getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    return `${page + 1} - ${
      Number((length / pageSize).toFixed(0)) === 0
        ? 1
        : Math.ceil(length / pageSize)
    }`;
  };

  return customPaginatorIntl;
}
export function isUndefinedEntity(element: unknown): element is undefined {
  return element === undefined;
}

export function filterArrayContent<T extends Partial<Record<keyof T, unknown>>>(
  arrayToFilter: Array<T>,
  filterProperty: keyof T,
  filterTerm: string,
  exclusivity: "some" | "every" = "every"
): Array<T> {
  const lowerCaseTerm = sanitizeSearchTerm(filterTerm).split(" ");
  return arrayToFilter.filter((arrayElement) => {
    if (Array.isArray(arrayElement[filterProperty])) {
      const filteringElements = arrayElement[filterProperty] as Array<string>;
      return lowerCaseTerm.every((comparingTo) =>
        filteringElements.some((property) =>
          sanitizeSearchTerm(property).includes(comparingTo)
        )
      );
    } else if (arrayElement[filterProperty] === undefined) {
      return sanitizeSearchTerm(filterTerm) === "";
    }
    const propertyValue = sanitizeSearchTerm(
      arrayElement[filterProperty] as string
    );
    return lowerCaseTerm[exclusivity]((comparingTo) =>
      propertyValue.includes(comparingTo)
    );
  });
}

/**
 * Sorts an array of objects based on a specified property.
 * @param arrayToSort The array of objects to be sorted.
 * @param sortingProperty The property of the objects by which to sort.
 * @param sortDirection Optional. Determines the sorting direction. Defaults to false (ascending).
 * @throws Throws an error if the sorting property does not represent a string or number.
 * @template T The type of objects in the array.
 */
export function sortArrayContent<T>(
  arrayToSort: Array<T>,
  sortingProperty: keyof T,
  sortDirection = false
): void {
  arrayToSort.sort((a, b) => {
    const leftCompare = sortDirection ? a[sortingProperty] : b[sortingProperty];
    const rightCompare = sortDirection
      ? b[sortingProperty]
      : a[sortingProperty];
    if (typeof leftCompare == "string" && typeof rightCompare == "string") {
      return leftCompare.localeCompare(rightCompare);
    } else if (
      typeof leftCompare == "number" &&
      typeof rightCompare == "number"
    ) {
      return leftCompare - rightCompare;
    }
    throw new Error(
      `Not possible to sort by ${sortingProperty.toString()}, it must be represent a string or number`
    );
  });
}

/**
 * Wraps the passed JSON object in a value layer that is inbetween the key and the actual object value, does it recursively.
 * @remarks
 * This method will modify the structure of the first parameter so if you want to keep the original, pass a copy of it here!
 * @param dataToUnwrap - A JSON object which properties will be wrapped.
 * @param skipKeyUnwrap - If the object key property matches this key it will skip wrapping for that object property.
 * @param skipIfKeyPresent - If value of the current object is also an object and if that values contains this key it will skip wrapping.
 * @returns A wrapped JSON object.
 */
export function wrapDataInValue(
  dataToWrap: TJsonObject,
  skipKeyWrap?: string,
  skipIfKeyPresent?: string
): TJsonObject {
  for (const [key, objectValue] of Object.entries(dataToWrap)) {
    if (entrySkip(key, objectValue, skipKeyWrap)) {
      continue;
    }
    if (Array.isArray(objectValue)) {
      const fixedArray = objectValue.map((innerValue) =>
        wrapDataInValue(innerValue)
      );
      dataToWrap[key] = { value: fixedArray };
    } else if (typeof objectValue === "object") {
      if (
        skipIfKeyPresent &&
        objectPropertyExists(objectValue as TJsonObject, skipIfKeyPresent)
      ) {
        continue;
      }
      dataToWrap[key] = {
        value: wrapDataInValue(
          objectValue as TJsonObject,
          skipKeyWrap,
          skipIfKeyPresent
        ),
      };
    } else {
      dataToWrap[key] = { value: objectValue };
    }
  }
  return dataToWrap;
}

/**
 * Unwraps the passed JSON object from the value layer that is inbetween the key and actual object value
 * @remarks
 * This method will modify the structure of the first parameter so if you want to keep the original, pass a copy of it here!
 * @param dataToUnwrap - A JSON object which properties will be unwrapped
 * @param skipKeyUnwrap - If the object key property matches this key it will skip unwrapping for that object property
 * @param skipIfKeyPresent - If value of the current object is also an object and if that values contains this key it will skip unwrapping
 * @returns An unwrapped JSON object, you can also ignore the return and used the the first param because they are the both the new requested object
 */
export function unwrapDataFromValue<Target extends TJsonObject>(
  dataToUnwrap: TJsonObject | TJsonObject[],
  skipKeyUnwrap?: string,
  skipIfKeyPresent?: string
): Target {
  Object.entries(dataToUnwrap).forEach(([key, value]) => {
    if (entrySkip(key, value, skipKeyUnwrap)) return;
    if (Array.isArray(value)) {
      dataToUnwrap = value.map<Target>((innerObject) =>
        unwrapDataFromValue(innerObject, skipKeyUnwrap, skipIfKeyPresent)
      );
      return;
    }
    if (typeof value === "object") {
      if (
        skipIfKeyPresent &&
        objectPropertyExists(value as TJsonObject, skipIfKeyPresent)
      )
        return;
      if (objectPropertyExists(value as TJsonObject, "value")) {
        (dataToUnwrap as TJsonObject)[key] = unwrapDataFromValue(
          value as TJsonObject,
          skipKeyUnwrap,
          skipIfKeyPresent
        );
        return;
      }
      dataToUnwrap = unwrapDataFromValue(
        value as TJsonObject,
        skipKeyUnwrap,
        skipIfKeyPresent
      );
      return;
    }
    dataToUnwrap = value as TJsonObject;
  });
  return dataToUnwrap as Target;
}

/**
 * Checks if the object contains the passed key.
 * @param targetObject Target object being checked.
 * @param property The property we are looking for.
 * @returns True if found, false otherwise.
 */
function objectPropertyExists(
  targetObject: TJsonObject,
  property: string
): boolean {
  return property in targetObject;
}

/**
 * Checks if the passed object key matches the needed constraints.
 * @param key Object key being checked.
 * @param value Object value being checked.
 * @param skipKey Dynamic constraint for the object key.
 * @returns True or false, depending if the object has satisfied the constraints.
 * @remark Also checks if the value is undefined of null.
 */
function entrySkip(key: string, value: unknown, skipKey?: string): boolean {
  return (
    key === "metadata" ||
    value === undefined ||
    value === null ||
    key === skipKey
  );
}

export function getInitials(param: string, splitter?: TSplitterType): string {
  const splitValue = param.split(splitter ?? " ");
  const initials = splitValue.map((value) => value[0]).join("");
  return initials;
}

export function sanitizeSearchTerm(searchTerm: string): string {
  return searchTerm.toLowerCase().trim();
}

/**
 * Forms the API header content.
 * @param headerContent The header content is represented by JSON object containing the header values,
 * that will be used to form the headers of the API call
 * @param response Could be xml or json.
 * @returns A formed and prepared headers object for the API call.
 */
export function formApiHeader(
  headerContent: TJsonObject<string | string[]>,
  response?: string,
  includedParams?: TJsonObject<string | number | boolean>
): IApiCallHeaders {
  const headerResponse: IApiCallHeaders = {
    headers: new HttpHeaders(),
    params: new HttpParams(),
    responseType: response ? (response as "json") : "json",
    withCredentials: true,
  };
  Object.entries(headerContent).forEach(([key, value]) => {
    headerResponse.headers = headerResponse.headers.append(key, value);
  });
  if (includedParams) {
    Object.entries(includedParams).forEach(([key, value]) => {
      headerResponse.params = headerResponse.params?.append(key, value);
    });
  }
  return headerResponse;
}

/**
 * Used to validate if a pair of forms have the same value.
 * @param firstForm The key of the first form control.
 * @param secondForm The key of the second form control.
 * @returns A validation error or null.
 */
export function mustMatchForms(
  firstForm: string,
  secondForm: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const firstControl = control.get(firstForm);
    const secondControl = control.get(secondForm);
    if (firstControl?.value !== secondControl?.value) {
      secondControl?.setErrors({ match: true });
      return { checkInput: true, requiredValue: control.get(firstForm)?.value };
    }
    secondControl?.setErrors(null);
    return null;
  };
}

export function mustBeEqual(targetValue: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value !== targetValue) {
      return { checkInput: true, requiredValue: targetValue };
    }
    return null;
  };
}

export function mustContainControl(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      (control instanceof FormGroup &&
        Object.keys(control.controls).length === 0) ||
      (control instanceof FormArray && control.length === 0)
    ) {
      return { controlEmpty: true };
    }
    return null;
  };
}


