@if (iconConfiguration.renderType === 'symbol') {
<span
  [ngClass]="[
    'material-symbols-' + (iconConfiguration.symbolType ?? 'outlined'),
    'size-' + (iconConfiguration.symbolOptSize ?? 'md'),
    'symbol-' + (iconConfiguration.symbolState ?? 'empty'),
    'gradiation-' + (iconConfiguration.symbolGrade ?? 'mid'),
    'weight-' + (iconConfiguration.symbolWeight ?? '400')
  ]"
  [ngStyle]="{
    'font-size': iconConfiguration.renderSize ?? '24px'
  }"
>
  {{ iconConfiguration.renderEntity }}
</span>
} @if (iconConfiguration.renderType === 'material' ||
!iconConfiguration.renderType) {
<mat-icon
  fontSet="material-icons-outlined"
  [ngStyle]="{
    width: iconConfiguration.renderSize ?? '24px',
    height: iconConfiguration.renderSize ?? '24px',
    'font-size': iconConfiguration.renderSize ?? '24px'
  }"
>
  {{ iconConfiguration.renderEntity }}
</mat-icon>
} @if (iconConfiguration.renderType === 'added') {
<mat-icon
  [svgIcon]="iconConfiguration.renderEntity"
  [ngStyle]="{
    width: iconConfiguration.renderSize ?? '24px',
    height: iconConfiguration.renderSize ?? '24px',
    'font-size': iconConfiguration.renderSize ?? '24px'
  }"
>
</mat-icon>
} @if (iconConfiguration.renderType === 'custom') {
<img
  [src]="iconConfiguration.renderEntity | safeUrl"
  [ngStyle]="{
    width: iconConfiguration.renderSize ?? '24px',
    height: iconConfiguration.renderSize ?? '24px',
    'font-size': iconConfiguration.renderSize ?? '24px'
  }"
  alt="custom icon"
/>
}
