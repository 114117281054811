import { IWizardStep } from "./interfaces";

/**
 * Used to insert a symbol into the wizard step content.
 * @param symbolName The symbol we are inserting.
 * For the full list of symbol chech the Google Fonts icon page
 * **https://fonts.google.com/icons?icon.set=Material+Symbols&icon.style=Outlined**.
 * @returns A string with the predefined symbol and set classes.
 */
export function insertSymbolEntity(symbolName: string): string {
  return `<span class="material-symbols-outlined va-m pi-4">${symbolName}</span>`;
}

export function wizardErrorStep(): IWizardStep {
  return {
    width: "250px",
    title: `Wizard error: Code 404`,
    content: [
      "The wizard has encountered an error. Please quit the wizard and contact support with the error code.",
    ],
    buttonChoices: [
      {
        jumpTo: -1,
        text: "Quit wizard",
        type: "flat",
      },
    ],
  };
}
