import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import {
  FormControl,
  NonNullableFormBuilder,
  Validators,
} from "@angular/forms";
import { userRole } from "src/app/modules/application/interfaces/application.types";
import { SnackbarService } from "src/app/modules/shared/services/snackbar.service";
import { GlobalStorageService } from "src/app/modules/shared/services/globalstorage.service";
import { WizardService } from "src/app/modules/shared/component/wizard-overlay/services/wizard.service";
import { RevisionsApiService } from "../../services/revisions-api.service";
import {
  IRevision,
  IRevisionResponse,
} from "src/app/modules/application-data/data-conversion-v2/utility/interfaces";
import {
  sortArrayContent,
  filterArrayContent,
  sanitizeSearchTerm,
} from "src/app/modules/shared/utilities/utils";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IDialogActions } from "../../utilities/interfaces";

@Component({
  selector: "revisions",
  templateUrl: "./revisions.component.html",
  styleUrls: ["./revisions.component.scss"],
})
export class RevisionsComponent
  implements OnInit, AfterViewInit, IDialogActions
{
  isLoading = false;
  role!: userRole;
  filterTerm = "";
  sort = false;
  revisionControl: FormControl<number>;
  revisionResponse: Array<IRevisionResponse> = [];
  constructor(
    private dialogRef: MatDialogRef<RevisionsComponent, string | undefined>,
    @Inject(MAT_DIALOG_DATA) public revisionData: IRevision,
    private _revisionApiService: RevisionsApiService,
    private _globalStorageService: GlobalStorageService,
    private _snackBarService: SnackbarService,
    private _wizardService: WizardService,
    private formBuilder: NonNullableFormBuilder
  ) {
    this.revisionControl = this.formBuilder.control<number>(
      0,
      Validators.required
    );
  }
  ngAfterViewInit(): void {
    this._wizardService.componentLoad();
  }

  ngOnInit(): void {
    this.getData();
    this.role = this._globalStorageService.applicationRole;
  }

  getData() {
    this.isLoading = true;
    this._revisionApiService
      .getHistory(this.revisionData.apiAddon, this.revisionData.id)
      .then((revisions) => {
        sortArrayContent(revisions, "timestamp", true);
        this.revisionResponse = revisions.filter(
          (historyPoint) => historyPoint.action !== 1
        );

        let foundRevisionPoint = 0;
        if (!this.revisionData.reference) {
          foundRevisionPoint = this.extractRevisionNumber(
            revisions[revisions.length - 1]
          );
        } else {
          const foundRevision = revisions.find(
            (revisionEntity) =>
              revisionEntity.revision === +this.revisionData.reference!
          );
          foundRevisionPoint = this.extractRevisionNumber(foundRevision);
        }
        this.revisionControl.patchValue(foundRevisionPoint);
        sortArrayContent(this.revisionResponse, "revision", this.sort);
        this.isLoading = false;
      })
      .catch((e) => {
        this._snackBarService.open(
          "failure",
          `Failed fetching ${this.revisionData.title} history`
        );
        this.isLoading = false;
      });
  }

  onSubmit(): void {
    if (this.revisionControl.value.toString() === this.revisionData.reference) {
      this.onClose();
      return;
    }
    this.dialogRef.close(this.revisionControl.value.toString());
  }
  onClose(): void {
    this.dialogRef.close();
  }

  setRevision(revisionVersion: number) {
    this.revisionControl.setValue(revisionVersion);
  }

  sortRevisions() {
    this.sort = !this.sort;
    sortArrayContent<IRevisionResponse>(
      this.revisionResponse,
      "revision",
      this.sort
    );
  }

  get filteredRevisions() {
    return filterArrayContent(
      this.revisionResponse,
      "tags",
      sanitizeSearchTerm(this.filterTerm)
    );
  }

  private extractRevisionNumber(extractingFrom?: IRevisionResponse): number {
    if (!extractingFrom) return 0;
    return extractingFrom.action === 0
      ? extractingFrom.revision
      : extractingFrom.restoredRevision!;
  }
}
