<div
  role="dialog"
  aria-modal="true"
  cdkDrag
  cdkDragBoundary="body"
  class="frame-window flex-column mat-elevation-z4"
  [cdkDragDisabled]="state === 'max'"
  [ngClass]="state === 'max' ? 'maximized' : 'scaled'"
>
  <div class="frame-window-control flex-row gap-4">
    <div class="flex-row gap-4 menu left">
      @if (state !== 'max') {
      <div
        cdkDragHandle
        class="interactable-button drag-handle flex-row align-center"
      >
        <generic-icon
          [iconConfiguration]="{ renderEntity: 'drag_indicator' }"
        />
      </div>
      }
    </div>
    <div class="flex-row gap-4 menu right">
      <button
        tabindex="0"
        mat-icon-button
        class="interactable-button density-mid"
        matTooltipPosition="above"
        matTooltip="New window"
        (click)="handleOpenNewWindow()"
      >
        <generic-icon
          [iconConfiguration]="{
            renderEntity: 'open_in_new',
            renderType: 'symbol'
          }"
        />
      </button>
      <button
        tabindex="0"
        mat-icon-button
        class="interactable-button density-mid"
        matTooltipPosition="above"
        [matTooltip]="state === 'max' ? 'Shrink' : 'Expand'"
        (click)="handleWindowSize()"
      >
        <generic-icon
          [iconConfiguration]="{
            renderEntity: state === 'max' ? 'fullscreen_exit' : 'fullscreen',
            renderType: 'symbol'
          }"
        />
      </button>
      <button
        mat-icon-button
        tabindex="0"
        class="interactable-button density-mid"
        matTooltipPosition="above"
        matTooltip="Close"
        (click)="handleCloseGuide()"
      >
        <generic-icon
          [iconConfiguration]="{ renderEntity: 'close', renderType: 'symbol' }"
        />
      </button>
    </div>
  </div>
  @if (safeUrl) {
  <iframe [src]="safeUrl" title="Help guide" loading="lazy"></iframe>
  }
</div>
