import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { GuideFrameService } from "../../services/guide-frame.service";

@Component({
  selector: "frame-window",
  templateUrl: "./frame-window.component.html",
  styleUrls: ["./frame-window.component.scss"],
  host: {
    style: "display:contents",
  },
})
export class FrameWindowComponent implements OnInit {
  @Input() iframeUrl: string | undefined;

  safeUrl: SafeUrl | undefined;

  state: "max" | "default";
  constructor(
    private sanitizer: DomSanitizer,
    private _guideFrameService: GuideFrameService
  ) {
    this.state = "max";
  }

  ngOnInit(): void {
    this.generateSafeUrl();
  }

  private generateSafeUrl() {
    if (!this.iframeUrl) return;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeUrl
    );
  }

  handleCloseGuide() {
    this._guideFrameService.deactivateHelpGuide();
  }
  handleWindowSize() {
    this.state = this.state === "max" ? "default" : "max";
  }
  handleOpenNewWindow() {
    if (!this.iframeUrl) return;
    this._guideFrameService.deactivateHelpGuide();
    window.open(this.iframeUrl);
  }
}
