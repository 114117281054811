import { Pipe, PipeTransform } from "@angular/core";
import { sanitizeSearchTerm } from "../utilities/utils";

@Pipe({
  name: "filterBy",
})
export class FilteringPipe implements PipeTransform {
  transform(
    arrayToFilter: any[],
    filterProperty: string,
    filterTerm: string
  ): any[] {
    const lowerCaseTerm = sanitizeSearchTerm(filterTerm).split(" ");
    return arrayToFilter.filter((arrayElement) => {
      const innerValue = arrayElement[filterProperty];
      if (Array.isArray(innerValue)) {
        return lowerCaseTerm.every((comparingTo) =>
          innerValue.some((property) =>
            sanitizeSearchTerm(property).includes(comparingTo)
          )
        );
      } else if (innerValue === undefined) {
        return sanitizeSearchTerm(filterTerm) === "";
      } else {
        return sanitizeSearchTerm(innerValue).includes(lowerCaseTerm.join(" "));
      }
    });
  }
}
