import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { IGenericCardConfig } from "../../utilities/interfaces";

@Component({
  selector: "generic-card",
  templateUrl: "./generic-card.component.html",
  styleUrls: ["./generic-card.component.scss"],
})
export class GenericCardComponent implements OnInit {
  @Input({ alias: "config", required: true })
  configuration!: IGenericCardConfig;
  @HostBinding("class.fancy") fancyDisplay = false;
  ngOnInit(): void {
    this.fancyDisplay = !!this.configuration.isFancy;
  }
}
