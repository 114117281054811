import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "safeUrl",
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private _domSanitizer: DomSanitizer) {}

  transform(url: string): string {
    if (url) {
      return `data:image/svg+xml;base64,${url}`;
    }
    return '';
  }
}
