<h1 mat-dialog-title>
  <span>Add save description?</span>
</h1>
<form
  [formGroup]="saveContentControl"
  (ngSubmit)="onSubmit()"
  class="flex-column gap-8 density-mid"
>
  <mat-dialog-content class="scrollable">
    <mat-form-field>
      <mat-label>Enter message</mat-label>
      <input
        matInput
        data-cy="form-save-message"
        placeholder="Enter message..."
        formControlName="messages"
      />
    </mat-form-field>
    <mat-form-field style="min-height: 50px" class="tags-chip">
      <mat-label>Enter tags</mat-label>
      <mat-chip-grid color="primary" #chipList multiple formControlName="tags">
        @for (tag of saveContentControl.controls['tags'].value; track tag;let
        tagIndex=$index) {
        <mat-chip-row (removed)="removeTag(tagIndex)">
          {{ tag }}
          <button matChipRemove>
            <generic-icon
              [iconConfiguration]="{
                renderEntity: 'cancel',
                renderType: 'symbol',
                symbolOptSize: 'sm'
              }"
            />
          </button>
        </mat-chip-row>
        }
        <input
          data-cy="form-tags-input"
          matInput
          maxlength="20"
          #tagInput
          placeholder="New tag..."
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorActivations"
          (matChipInputTokenEnd)="addTagFromInput($event)"
          [matChipInputAddOnBlur]="true"
        />
      </mat-chip-grid>
      <mat-hint class="adjustments">
        {{ tagInput.value.length || 0 }}/20
      </mat-hint>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-stroked-button
      data-cy="cancel-form"
      color="primary"
      type="button"
      (click)="onClose()"
    >
      No
    </button>
    <button
      data-cy="submit-form"
      mat-raised-button
      color="accent"
      type="submit"
    >
      Add
    </button>
  </mat-dialog-actions>
</form>
