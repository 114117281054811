import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { SnackbarService } from "src/app/modules/shared/services/snackbar.service";
import { GlobalStorageService } from "../../../services/globalstorage.service";
import { WizardOverlayComponent } from "../wizard-overlay.component";
import {
  IWizardConnectionOptions,
  IWizardInterference,
  IWizardStep,
} from "../utilities/interfaces";
import { TTargetData } from "../utilities/types";
import { wizardErrorStep } from "../utilities/utils";

@Injectable({
  providedIn: "root",
})
export class WizardService {
  private wizardSubject: BehaviorSubject<IWizardStep | undefined> =
    new BehaviorSubject<IWizardStep | undefined>(undefined);
  wizardStep$ = this.wizardSubject.asObservable();

  private wizardAction: Subject<boolean> = new Subject();

  get wizardActionResponse(): Observable<boolean> {
    return this.wizardAction.asObservable();
  }

  private currentGuide: Array<IWizardStep> | undefined;
  private renderer2!: Renderer2;
  private overlayRef!: OverlayRef;
  private targetedElement: HTMLElement | undefined | null;
  private guideToDisplay = "";
  private specialCase = "";

  private currentStep = 0;
  private stepOverride: number | undefined = undefined;

  private active = false;
  private fallbackActive = false;
  private modified = true;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _snackbarService: SnackbarService,
    private _globalStorageService: GlobalStorageService,
    private overlay: Overlay,
    private renderer: RendererFactory2,
    private dialogControl: MatDialog,
    private positionBuilder: OverlayPositionBuilder,
    private router: Router
  ) {
    this.renderer2 = renderer.createRenderer(null, null);
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.positionBuilder
        .global()
        .centerHorizontally()
        .centerVertically(),
      backdropClass: "wizard-blurry-back",
    });
  }

  //#region wizard activate/deactivate
  /**
   * Will activate the wizard by loading the guide and generating the wizard overlay component.
   */
  async activateWizard(): Promise<void> {
    try {
      await this.loadGuide();
      this.active = true;
      this.setStep(this.stepOverride ?? 0);
      const wizardOverlay = new ComponentPortal(WizardOverlayComponent);
      this.overlayRef.attach(wizardOverlay);
      this.renderer2.setStyle(this.overlayRef.overlayElement, "z-index", 1500);
      const parentCdkOverlay = this.renderer2.parentNode(
        this.renderer2.parentNode(this.overlayRef.overlayElement)
      );
      if (parentCdkOverlay) {
        this.renderer2.setStyle(parentCdkOverlay, "z-index", "unset");
        this.renderer2.setStyle(parentCdkOverlay, "position", "relative");
      }
    } catch (e) {
      this._snackbarService.open("failure", "Failed to open help");
      console.log(e);
      this.active = false;
    }
  }
  /**
   * Will deactivate the wizard by removing the overlay, resetting the current active step and triggering any expecting responces.
   */
  deactivateWizard(): void {
    this.currentStep = 0;
    this.active = false;
    this.removeInteraction();
    const parentCdkOverlay = this.renderer2.parentNode(
      this.renderer2.parentNode(this.overlayRef.overlayElement)
    );
    if (parentCdkOverlay) {
      this.renderer2.removeStyle(parentCdkOverlay, "z-index");
      this.renderer2.removeStyle(parentCdkOverlay, "position");
    }
    this.renderer2.removeStyle(this.overlayRef.overlayElement, "z-index");
    this.overlayRef.detach();
    this.wizardResponds();
  }

  /**Triggers the wizard responce subject. Any component waiting on the wizard to respond will be triggered and act in a predefined way. */
  wizardResponds(): void {
    this.wizardAction.next(true);
  }
  //#endregion

  //#region guide load management and outside step management
  /**
   * Will set the guide to a specific wizard component. If the wizard is active it will try loading that specific guide.
   * @param guideToLoad Used to set what component guide we are loading (conversion, workflow, protocol, ...).
   */
  async setGuide(guideToLoad: string): Promise<void> {
    this.guideToDisplay = guideToLoad;
    if (this.active) {
      await this.loadGuide();
    }
    this.removeOverrideStep();
  }
  /**
   * Gets the current state of the wizard guide.
   * @returns True if wizard is active, false otherwise.
   */
  get guideActive(): boolean {
    return this.active;
  }

  private setOverrideStep(step: number): void {
    this.stepOverride = step;
  }

  private removeOverrideStep(): void {
    this.stepOverride = undefined;
  }

  /**
   * Loads the current set guide. It will try to load the guide content from the ../guides folder.
   * @remark Any new guides should be added to that folder under the correct group/module.
   */
  private async loadGuide(): Promise<void> {
    try {
      this.currentGuide = (await import(`../guides/${this.guideToDisplay}`))
        .guide as Array<IWizardStep>;
    } catch (e) {
      this._snackbarService.open(
        "failure",
        `Failed to load guide ${this.guideToDisplay}`
      );
      this.active = false;
    }
  }
  //#endregion

  /**
   * Switched the wizard overlay window to a loading state.
   * It's used when switching between different guides or waiting for an async request to complete.
   */
  private setLoading(): void {
    this.wizardSubject.next(undefined);
    this.overlayRef.updatePositionStrategy(
      this.positionBuilder.global().centerHorizontally().centerVertically()
    );
  }

  /**
   * Used to indicate to the wizard that a component has loaded and it should stop the loading state and load the appropriate step of the guide.
   * @param overrideStep If provided, will override any previously set step
   * and will be used as a starting point for the current set wizard guide.
   * @remark The overrideStep is cleared only when setting a new wizard guide.
   */
  componentLoad(overrideStep?: number): void {
    if (overrideStep) {
      this.setOverrideStep(overrideStep);
    }
    if (this.active) {
      this.setStep(overrideStep ?? this.currentStep);
    }
  }

  /**
   * Used in components to indicate to the wizard that a step change should happen from the outsideInteraction property.
   * @param step If provided correlates to the index of the action we want to take in the outsideInteraction array in the current guide step.
   * If not provided the outsideInteraction property will be treated as an object instead of an array.
   * @returns True and the component method that called this component will interrupt its usual behaviour.
   */
  outsideInteraction(step?: number): boolean {
    if (this.isImpossibleInteraction()) {
      return false;
    }
    if (this.isActionBlocked(this.currentGuide?.[this.currentStep], step)) {
      return true;
    }
    const possibleAction = this.extractStep(
      this.currentGuide?.[this.currentStep],
      step
    );
    if (!possibleAction) {
      return false;
    }
    if (possibleAction.respond) {
      this.wizardResponds();
    }
    if (possibleAction.triggerLoad) {
      this.setLoading();
      this.removeInteraction();
      this.currentStep = possibleAction.jumpTo;
    } else {
      this.setStep(possibleAction.jumpTo);
    }
    return false;
  }

  /**
   * Sets the wizard fallback state. If true will read the fallback step in the outsideInteraction property.
   * @param state Should it trigger the fallback state or not.
   */
  setFallbackState(state: boolean): void {
    this.fallbackActive = state;
  }
  /**
   * Returns the current fallback state
   * @returns True if the fallback state is active, false otherwise
   */
  getFallBackState(): boolean {
    return this.fallbackActive;
  }

  /**
   * When called will remove the interaction and no longer highlight a target element on the page.
   */
  private removeInteraction(): void {
    if (!this.targetedElement) {
      return;
    }
    this.renderer2.removeClass(this.targetedElement, "wizard-highlight");
    this.renderer2.removeClass(this.targetedElement, "wizard-highlight-block");
    if (this.specialCase !== "") {
      this.renderer2.removeClass(
        this.targetedElement,
        `wizard-highlight-block-${this.specialCase}`
      );
    }
    this.renderer2.setStyle(this.overlayRef.overlayElement, "z-index", 1500);
    this.specialCase = "";
    delete this.targetedElement;
  }
  //#region Step processing and management

  /**
   * This method is used to trigger a route/component change. Is used to trigger navigation from the wizard window as a part of a step.
   * @param route Is used to set to what route we are navigating to. Defined like an array of routing keywords.
   * @param step A specific step we want to load in the guide after successfully routing to the component.
   */
  specialRouting(route: Array<string>, step: number): void {
    this.setLoading();
    this.removeInteraction();
    this.currentStep = step;
    this.router
      .navigate([
        `/applications/${this._globalStorageService.applicationId}/`,
        ...route,
      ])
      .catch(() => {
        this.deactivateWizard();
      });
  }
  /**
   * When this method is called, if there is an active dialog as a wizard target, it will close the dialog.
   * Primarily used with the step back button in the wizard window.
   * @param step Loads the provided step in the guide after closing the dialog.
   */
  specialDialog(step: number): void {
    const targetDialogId =
      this.currentGuide?.[this.currentStep]?.connectTo?.target.value;
    if (!targetDialogId) {
      this.setErrorStep(`The dialog couldn't be closed, 
      there was no dialog id found in the wizard step. 
      Check the content of the wizard step, it could be missing some crucial data.`);
      return;
    }
    this.dialogControl.getDialogById(targetDialogId)?.close();
    this.removeInteraction();
    this.setStep(step);
  }

  /**
   * Sets the current step of the guide and loads it.
   * @param step Step index to be loaded.
   */
  setStep(step: number): void {
    this.currentStep = step;
    this.wizardSubject.next(this.currentGuide?.[this.currentStep]);
    this.processStep();
  }

  setErrorStep(errorMessage: string): void {
    console.error(errorMessage);
    this.wizardSubject.next(wizardErrorStep());
    this.updateTargetPosition("center");
  }
  /**
   * When called, the wizard-highlight class from the targeted element will be removed.
   * It's used in combination with the dropdowns as a z-index fix.
   * @param state Decides if the background should be added or removed.
   */
  temporaryBackground(state: boolean): void {
    if (!this.active || !this.targetedElement) {
      return;
    }
    state
      ? this.renderer2.removeClass(this.targetedElement, "wizard-highlight")
      : this.renderer2.addClass(this.targetedElement, "wizard-highlight");
  }

  /**
   * Removes the current highlighted elements styles and processes the current new set step.
   * If the current guide step has a set connectTo property it will target that element as a new highlighted entity.
   */
  private processStep(): void {
    if (!this.currentGuide) {
      return;
    }
    //Connecting to a property or centering on screen
    this.removeInteraction();
    const targetConnectData = this.currentGuide[this.currentStep]?.connectTo;
    if (targetConnectData) {
      this.targetedElement = this.fetchTargetEntity(targetConnectData.target);

      if (!this.targetedElement) {
        this.setErrorStep(`Couldn't find the target element with 
        ${targetConnectData.target.key}=${targetConnectData.target.value}.
      Check the wizard step configuration, it could be missing or contains incorrect data.`);
        return;
      }
      this.handleConnectionCases(this.targetedElement, targetConnectData);
      this.updateTargetPosition(
        "targeted",
        this.targetedElement,
        targetConnectData.positions
      );
      this.modified = true;
    } else if (this.modified) {
      this.updateTargetPosition("center");
      this.modified = false;
    }
  }

  /**
   * Affects the current position of the wizard window.
   * @param position If set to "targeted" it will anchor to the targeted element, otherwise it will be centered on the screen.
   * @param target If defined will be used as an anchor for the wizard window.
   * @param positions Positions that the wizard window will use while following the anchor target element.
   */
  private updateTargetPosition(
    position: "center" | "targeted",
    target?: HTMLElement,
    positions?: ConnectedPosition[]
  ): void {
    if (position === "targeted" && target && positions) {
      this.overlayRef.updateScrollStrategy(
        this.overlay.scrollStrategies.reposition()
      );
      this.overlayRef.updatePositionStrategy(
        this.positionBuilder
          .flexibleConnectedTo(target)
          .withPositions(positions)
      );
      return;
    }
    this.overlayRef.updatePositionStrategy(
      this.positionBuilder.global().centerHorizontally().centerVertically()
    );
  }
  private handleConnectionCases(
    targetElement: HTMLElement,
    targetConfiguration: IWizardConnectionOptions
  ): void {
    if (targetConfiguration.disable) {
      this.renderer2.addClass(targetElement, "wizard-highlight-block");
    }
    if (targetConfiguration.appendCssClass) {
      this.specialCase = targetConfiguration.appendCssClass;
      this.renderer2.addClass(
        targetElement,
        `wizard-highlight-block-${targetConfiguration.appendCssClass}`
      );
    }
    if (targetConfiguration.isInFront) {
      this.renderer2.setStyle(this.overlayRef.overlayElement, "z-index", 1000);
    }
    this.renderer2.addClass(targetElement, "wizard-highlight");
  }

  /**Tries to look for and fetch the target entity.
   * @param targetData Contains the configuration data for the target entity.
   */
  private fetchTargetEntity(targetData: TTargetData): HTMLElement | null {
    if (targetData.key === "id") {
      return this._document.getElementById(targetData.value);
    }
    return this._document.querySelector<HTMLElement>(
      `[${targetData.key}="${targetData.value}"]`
    );
  }
  //#endregion

  /**
   * Checks if the wizard is active and if the outside step is defined for the current step.
   * @returns True if the outside interaction is not possible (wizard deactivated or undefined step).
   */
  private isImpossibleInteraction(): boolean {
    return (
      !this.active ||
      this.currentGuide?.[this.currentStep]?.outsideInteraction === undefined
    );
  }

  /**
   * Checks if the outside interaction is blocked by the wizard.
   * @param wizardStep The current step.
   * @param nextStep The next target step.
   * @returns True if the outside interaction should be blocked and interrupt the flow.
   */
  private isActionBlocked(
    wizardStep: IWizardStep | undefined,
    nextStep: number | undefined
  ): boolean {
    if (wizardStep?.allowedOutside === undefined || nextStep === undefined)
      return false;
    if (Array.isArray(wizardStep?.allowedOutside)) {
      return !wizardStep.allowedOutside.includes(nextStep);
    }
    return wizardStep.allowedOutside !== nextStep;
  }

  private extractStep(
    wizardStep: IWizardStep | undefined,
    nextStep: number | undefined
  ): IWizardInterference | undefined {
    if (Array.isArray(wizardStep?.outsideInteraction)) {
      if (nextStep === undefined) {
        return undefined;
      }
      return wizardStep?.outsideInteraction[nextStep];
    }
    if (nextStep !== undefined) {
      return undefined;
    }
    return wizardStep?.outsideInteraction;
  }
}
