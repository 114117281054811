import { Component, Input } from "@angular/core";

@Component({
  selector: "generic-context-loader",
  templateUrl: "./generic-context-loader.component.html",
  styleUrls: ["./generic-context-loader.component.scss"],
})
export class GenericContextLoaderComponent {
  /**The text that will displayed above the loading bar. */
  @Input({alias:'loadingText'}) loaderText: string;
  /**Set the loader theme to light or default. */
  @Input() theme: "default" | "light";
  /**The gap size between the loading text and the bar. */
  @Input() gapSize: number;
  /**Size of the loading text font. Just the basic number input. */
  @Input() fontSize: number;


  constructor() {
    this.loaderText = "Loading";
    this.gapSize = 8;
    this.fontSize = 22;
    this.theme = "default";
  }
}
