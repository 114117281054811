import { Component, EventEmitter, Input, Output } from "@angular/core";
import { WizardService } from "src/app/modules/shared/component/wizard-overlay/services/wizard.service";

@Component({
  selector: "app-table-card-header",
  templateUrl: "./table-card-header.component.html",
  styleUrls: ["./table-card-header.component.scss"],
})
export class TableCardHeaderComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() includeDialogHandler: boolean;
  @Input() includeSearchBar: boolean;
  @Input() counter?: number;
  @Input() buttonText = "Create";

  @Output() createEvent = new EventEmitter();
  @Output() searchEvent = new EventEmitter();
  @Output() searchTermChange = new EventEmitter<string>();

  constructor(private _wizardService: WizardService) {
    this.title = "";
    this.subtitle = "";
    this.includeSearchBar = false;
    this.includeDialogHandler = false;
  }

  handleAddClick(): void {
    this.createEvent.emit();
    this._wizardService.outsideInteraction();
  }
  onSearch(text: string): void {
    this.searchTermChange.emit(text);
  }
}
