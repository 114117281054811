import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { fromEvent, Subject, identity } from "rxjs";
import { tap, debounceTime, takeUntil, filter, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "generic-search-bar",
  templateUrl: "./generic-search-bar.component.html",
  styleUrls: ["./generic-search-bar.component.scss"],
})
export class GenericSearchBarComponent implements AfterViewInit, OnDestroy {
  @ViewChild("customSearch", { static: true })
  customSearch!: ElementRef;

  @Input() title = "";
  @Input() isSmall = false;
  @Input() includeDebouncer: boolean;
  @Input() filterTerm: string;
  @Output() filterTermChange: EventEmitter<string> = new EventEmitter<string>();

  private componentIsDestroyed: Subject<boolean>;

  searching = false;
  constructor() {
    this.filterTerm = "";
    this.componentIsDestroyed = new Subject<boolean>();
    this.includeDebouncer = true;
  }
  ngOnDestroy(): void {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.complete();
  }

  ngAfterViewInit(): void {
    this.keyboardInteraction.subscribe(() => {
      this.searching = false;
      this.filterTermChange.emit(this.filterTerm);
    });
  }

  clear() {
    this.filterTerm = "";
    this.filterTermChange.emit("");
  }

  private get keyboardInteraction() {
    return fromEvent<KeyboardEvent>(
      this.customSearch.nativeElement,
      "keyup"
    ).pipe(
      filter((keyEvent) => keyEvent.code !== "Tab"),
      tap((x) => {
        this.searching = true;
      }),
      distinctUntilChanged(),
      takeUntil(this.componentIsDestroyed),
      this.includeDebouncer ? debounceTime(600) : identity
    );
  }
}
