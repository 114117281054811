import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IDialogActions, IGenericDeleteForm } from "../../utilities/interfaces";
import { mustBeEqual } from "../../utilities/utils";

@Component({
  selector: "app-generic-delete-dialog",
  templateUrl: "./generic-delete-dialog.component.html",
  styleUrls: ["./generic-delete-dialog.component.scss"],
})
export class GenericDeleteDialogComponent implements OnInit, IDialogActions {
  nameMatch: FormControl<string | null>;
  constructor(
    public dialogRef: MatDialogRef<GenericDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IGenericDeleteForm
  ) {
    this.nameMatch = new FormControl<string>("");
  }

  ngOnInit(): void {
    this.setupForm();
  }

  onSubmit() {
    if (this.nameMatch.invalid) return;
    this.dialogRef.close(true);
  }

  onClose() {
    this.dialogRef.close();
  }
  private setupForm() {
    this.nameMatch.addValidators(mustBeEqual(this.data.name));
    this.nameMatch.updateValueAndValidity();
  }
}
