import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  SkipSelf,
} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { GlobalStorageService } from "src/app/modules/shared/services/globalstorage.service";
import { ChipService } from "../../../application-data/services/chip.service";
import { userRole } from "../../../application/interfaces/application.types";
import { IDiagramVariable } from "../../../application_workflow/utilities/interfaces";
import { IProtocol } from "src/app/modules/application-data/data-conversion-v2/utility/interfaces";

/**
This component is responsible for visually representing an array of entities as chips.
It is intended to solely handle the display of chips without involving any significant processing logic.
@remark Chip data is retrieved from the **ChipService**.
Note that the array of chips passed through the service is by **reference**, hence any modifications should consider this.
*/
@Component({
  selector: "chips-display",
  templateUrl: "./chips.component.html",
  styleUrls: ["./chips.component.scss"],
})
export class ChipsComponent implements OnInit, OnDestroy {
  /**The displayed title above the chip list. */
  @Input({ required: true }) title = "";
  /** */
  @Input() enableRemove = false;
  /**If true any interaction with the chips is blocked. For preview purposes. */
  @Input() previewChips = false;

  @Output() addChip = new EventEmitter();
  @Output() chipClick = new EventEmitter();
  @Output() chipRemove = new EventEmitter<IProtocol | IDiagramVariable>();
  @Output() chipAdded = new EventEmitter();

  role: userRole;
  chips: Array<IProtocol | IDiagramVariable>;
  first = false;
  overflow = false;

  private componentIsDestroyed: Subject<boolean> = new Subject();
  constructor(
    @SkipSelf()
    private _chipService: ChipService,
    private _globalStorageService: GlobalStorageService
  ) {
    this.role = this._globalStorageService.applicationRole;
    this.chips = [];
  }
  ngOnDestroy(): void {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.complete();
  }

  ngOnInit(): void {
    this.appendListeners();
  }

  private appendListeners() {
    this._chipService.allChips
      .pipe(takeUntil(this.componentIsDestroyed))
      .subscribe((chips) => {
        if (chips) {
          this.chips = chips;
        }
      });

    this._chipService.newChip
      .pipe(takeUntil(this.componentIsDestroyed))
      .subscribe((newChip) => {
        if (!this.chips || !newChip) return;
        if (!this.chips.find((chip) => chip.id === newChip.id)) {
          this.chips.push(newChip);
          this.chipAdded.emit();
        }
      });
  }

  selectChip(selectedChip: IProtocol | IDiagramVariable) {
    if (this.role !== "VIEWER") {
      this.chipClick.emit(selectedChip);
    }
  }

  handleAdd() {
    this.addChip.emit();
  }
  handleRemove(selectedChip: IProtocol | IDiagramVariable) {
    if (this.role !== "ADMIN") return;
    this.chipRemove.emit(selectedChip);
  }
}
