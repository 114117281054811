<div
  data-wizard-target="revision-drop-choice-interaction"
  class="top-revision-keep mat-elevation-z3"
>
  <span>Set as latest?</span>
  <div class="top-revision-actions">
    <button
      data-cy="accept-revision"
      mat-icon-button
      (click)="handleRevision.emit(true)"
    >
      <mat-icon>check</mat-icon>
    </button>
    <button
      data-cy="decline-revision"
      mat-icon-button
      (click)="handleRevision.emit(false)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
