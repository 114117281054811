import { Component, Inject } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";

@Component({
  selector: "generic-snackbar",
  templateUrl: "./generic-snackbar.component.html",
  styleUrls: ["./generic-snackbar.component.scss"],
})
export class GenericSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<GenericSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}
}
