import { Injectable } from "@angular/core";
import {
  AppApplication,
  userRole,
} from "../../application/interfaces/application.types";

@Injectable({
  providedIn: "root",
})
export class GlobalStorageService {
  private selectedApplication!: AppApplication;
  private preview: boolean;
  constructor() {
    this.preview = false;
  }

  public setApplication(application: AppApplication):void {
    this.selectedApplication = application;
  }

  get application(): AppApplication {
    return this.selectedApplication;
  }

  get applicationRole(): userRole {
    return this.preview ? "VIEWER" : this.selectedApplication.role;
  }

  get applicationId(): string {
    return this.selectedApplication.id;
  }

  previewModeOn(): void {
    this.preview = true;
  }

  previewModeOff(): void {
    this.preview = false;
  }
}
