import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BacksplashService {
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router
  ) {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        this.endLoading();
      });
  }

  endLoading(): void {
    const backsplashContainer = this._document.body.querySelector(
      'div[data-target="loading-backsplash"]'
    );
    if (!backsplashContainer) return;
    backsplashContainer.addEventListener(
      "animationend",
      () => {
        backsplashContainer.remove();
      },
      { once: true }
    );

    backsplashContainer.classList.add("fade-out");
  }
}
