import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from "@angular/core";

/**Creates a loader generic entity to go over the page.*/
@Directive({
  selector: "[relativeLoader]",
})
export class RelativeLoaderDirective implements OnChanges {
  private loader: HTMLElement;
  /**The loading toggle. */
  @Input() loading = false;
  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.renderer.setStyle(this.el.nativeElement, "display", "contents");
    this.loader = this.renderer.createElement("div");
    this.createSimpleLoader();
  }
  ngOnChanges(): void {
    if (this.loading && this.el) {
      this.renderer.setStyle(this.el.nativeElement.firstChild, "opacity", "0");
      this.renderer.addClass(this.el.nativeElement.firstChild, "remove");
      this.renderer.appendChild(this.el.nativeElement, this.loader);
    } else {
      this.renderer.removeChild(this.el.nativeElement, this.loader);
      this.renderer.removeClass(this.el.nativeElement.firstChild, "remove");
      this.renderer.removeStyle(this.el.nativeElement.firstChild, "opacity");
    }
  }

  private createSimpleLoader(): void {
    this.renderer.setAttribute(this.loader, "data-cy", "entity-loading");
    this.renderer.setStyle(this.loader, "display", "flex");
    this.renderer.setStyle(this.loader, "flex-direction", "column");
    this.renderer.setStyle(this.loader, "justify-content", "center");
    this.renderer.setStyle(this.loader, "align-items", "center");
    this.renderer.setStyle(this.loader, "position", "absolute");
    this.renderer.setStyle(this.loader, "top", "0");
    this.renderer.setStyle(this.loader, "bottom", "0");
    this.renderer.setStyle(this.loader, "left", "0");
    this.renderer.setStyle(this.loader, "right", "0");
    const ldsRoller = this.renderer.createElement("div");
    this.renderer.addClass(ldsRoller, "lds-roller");
    this.renderer.appendChild(ldsRoller, this.createArrow("light"));
    this.renderer.appendChild(ldsRoller, this.createArrow("dark"));
    [0, 1, 2, 3, 4, 5].forEach(() => {
      const div = this.renderer.createElement("div");
      this.renderer.appendChild(ldsRoller, div);
    });
    this.renderer.appendChild(this.loader, ldsRoller);
  }

  private createArrow(color: "light" | "dark"): HTMLDivElement {
    const createdArrow = this.renderer.createElement("div");
    this.renderer.addClass(createdArrow, `${color}-arrow`);
    return createdArrow;
  }
}
