<div
  class="generic-box flex-column"
  [style.gap.px]="gapSize"
  [class.light-theme]="theme === 'light'"
>
  @if (loaderText !== '') {
  <span [style.font-size.px]="fontSize">{{ loaderText }}</span>
  }
  <mat-progress-bar
    class="loading-bar"
    [class.light-theme]="theme === 'light'"
    mode="buffer"
  />
</div>
