import { Component, ViewContainerRef } from "@angular/core";
import { BacksplashService } from "./modules/shared/services/backsplash.service";
import { GuideFrameService } from "./modules/shared/services/guide-frame.service";
import { ThemeService } from "./modules/shared/services/theme.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "HORIZON VIEW";
  constructor(
    private _guideFrameService: GuideFrameService,
    private viewContainerRef: ViewContainerRef,
    private _backsplashService: BacksplashService,
    private _themeService: ThemeService
  ) {
    this._guideFrameService.setViewContainer(this.viewContainerRef);
  }
}
