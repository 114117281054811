@if (configuration.iconConfiguration) {
<generic-icon [iconConfiguration]="configuration.iconConfiguration" />
}
<div class="flex-column flex-grow card-content">
  <span class="content">
    {{ configuration.content }}
  </span>
  @if (configuration.subContent !== undefined) {
  <span class="sub-content">
    {{ configuration.subContent }}
  </span>
  }
</div>
@if (configuration.outputConfig; as output) {
<div class="flex-row gap-4 align-center">
  <ng-container
    [ngTemplateOutlet]="output.cardActions"
    [ngTemplateOutletContext]="$any({ cardEntity: output.outputEntity })"
  />
</div>
}
