import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { FrameWindowComponent } from "../component/frame-window/frame-window.component";

@Injectable({
  providedIn: "root",
})
export class GuideFrameService {
  private iframeComponentReference:
    | ComponentRef<FrameWindowComponent>
    | undefined;
  private viewContainer!: ViewContainerRef;

  setViewContainer(viewContainer: ViewContainerRef): void {
    this.viewContainer = viewContainer;
  }

  activateHelpGuide(url: string, chapter?: string): void {
    this.createFrameElement();
    this.setFrameUrl(url, chapter);
    document.body.style.overflow = "hidden";
  }

  deactivateHelpGuide(): void {
    this.removeFrameElement();
    document.body.style.overflow = "";
  }

  /**
   * If the iframe is active will set its url to load a specific page.
   * @param iframeUrl The url of the page we are loading in the iframe.
   * @param chapter Chapter relates to the specific part of the page we are loading, usually marked with the #header marking.
   */
  private setFrameUrl(iframeUrl: string, chapter?: string): void {
    if (!this.iframeComponentReference) return;
    this.iframeComponentReference.setInput("iframeUrl", iframeUrl);
  }

  private removeFrameElement(): void {
    if (!this.iframeComponentReference) return;
    this.iframeComponentReference.destroy();
    delete this.iframeComponentReference;
  }

  private createFrameElement(): void {
    if (this.iframeComponentReference) return;
    this.iframeComponentReference =
      this.viewContainer.createComponent<FrameWindowComponent>(
        FrameWindowComponent
      );
  }
}
