<div class="flex-column">
  <h2 [style.margin-bottom.rem]="0.25">{{ title }}</h2>
  <div class="flex-row gap-4 align-center chip-style-changes">
    @if (role !== 'VIEWER') {
    <button
      mat-icon-button
      [disabled]="previewChips"
      color="brand"
      (click)="handleAdd()"
    >
      <generic-icon
        [iconConfiguration]="{
          renderEntity: 'add_circle',
          renderType: 'symbol',
          symbolState: 'filled'
        }"
      />
    </button>
    }
    <mat-chip-set class="scroll flex-grow" aria-label="list">
      @for (chip of chips; track chip) { @if (chip.name) {
      <mat-chip
        matTooltip="{{ chip.description }}"
        [removable]="enableRemove"
        (removed)="handleRemove(chip)"
        (click)="selectChip(chip)"
        class="flex-row"
        [disabled]="$any(chip).type === 'PdfConfig' || previewChips"
        >{{ chip.name }}
        @if (enableRemove) {
        <generic-icon
          class="color-adjustment"
          matChipRemove
          [iconConfiguration]="{
            renderEntity: 'delete',
            renderType: 'symbol',
            symbolGrade: 'low'
          }"
        />
        }
      </mat-chip>
      } } @empty {
      <span [style]="{ 'font-weight': 500, padding: '0.5rem 1rem' }">
        No {{ title | lowercase }}
      </span>
      }
    </mat-chip-set>
  </div>
</div>
