import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({
  name: "search",
})
export class SearchPipe implements PipeTransform {
  transform<T extends Record<string, unknown>>(
    arr$: Observable<T[]>|undefined,
    searchTerm: string
  ): Observable<T[]> {
    const lSearchTerm = searchTerm.toLowerCase();
    const lValue = (value: unknown): string | undefined =>
      value?.toString().toLowerCase();
    if (arr$) {
      return arr$.pipe(
        map((arr) =>
          arr.filter((item) => {
            // search on all values
            return Object.values(item).some((value) =>
              lValue(value)?.includes(lSearchTerm)
            );
          })
        )
      );
    }
    return new Observable
  }
}
