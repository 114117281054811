import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Observable, Subject } from "rxjs";
import { environment } from "../../../../environments/environment";
import { SnackbarService } from "./snackbar.service";
import { IApiCallHeaders } from "../utilities/interfaces";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  static readonly BASE_URI = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService,
    private _snackbarService: SnackbarService
  ) {}

  public get<Response>(
    action: string,
    apiOpts?: IApiCallHeaders
  ): Observable<Response> {
    const subject = new Subject<Response>();
    const possibleApiOpts = apiOpts ?? {};
    this.keycloakService
      .updateToken()
      .then(() => {
        this.http
          .get<Response>(`${ApiService.BASE_URI}/${action}`, possibleApiOpts)
          .subscribe({
            next: (res) => {
              subject.next(res);
              subject.complete();
            },
            error: (err) => {
              subject.error(err);
              subject.complete();
            },
          });
      })
      .catch((e) => {
        this._snackbarService.open("failure", "Failed to update token");
        subject.error(e);
        subject.complete();
      });

    return subject.asObservable();
  }

  /**
   * @param action The url being targeted. There is no need to put the "/" prefix.
   * @param body Payload body.
   * @param apiOpts API header forms. Should contain the application id.
   */
  public post<Response>(
    action: string,
    body: unknown,
    apiOpts?: IApiCallHeaders
  ): Observable<Response> {
    const subject = new Subject<Response>();

    const headerOptionsMaybe = apiOpts ?? {};

    this.keycloakService
      .updateToken()
      .then(() => {
        this.http
          .post<Response>(
            `${ApiService.BASE_URI}/${action}`,
            body,
            headerOptionsMaybe
          )
          .subscribe({
            next: (res) => {
              subject.next(res);
              subject.complete();
            },
            error: (err) => {
              subject.error(err);
              subject.complete();
            },
          });
      })
      .catch((e) => {
        this._snackbarService.open("failure", "Failed to update token");
        subject.error(e);
        subject.complete();
      });
    return subject.asObservable();
  }

  public patch<Response>(
    action: string,
    body: unknown,
    apiOpts?: IApiCallHeaders
  ): Observable<Response> {
    const subject = new Subject<Response>();

    const headerOptionsMaybe = apiOpts ?? {};

    this.keycloakService
      .updateToken()
      .then(() => {
        this.http
          .patch<Response>(
            `${ApiService.BASE_URI}/${action}`,
            body,
            headerOptionsMaybe
          )
          .subscribe({
            next: (res) => {
              subject.next(res);
              subject.complete();
            },
            error: (err) => {
              subject.error(err);
              subject.complete();
            },
          });
      })
      .catch((e) => {
        this._snackbarService.open("failure", "Failed to update token");
        subject.error(e);
        subject.complete();
      });

    return subject.asObservable();
  }

  public delete<Response>(
    action: string,
    apiOpts?: IApiCallHeaders
  ): Observable<Response> {
    const subject = new Subject<Response>();

    const headerOptionsMaybe = apiOpts ?? {};

    this.keycloakService
      .updateToken()
      .then(() => {
        this.http
          .delete<Response>(
            `${ApiService.BASE_URI}/${action}`,
            headerOptionsMaybe
          )
          .subscribe({
            next: (res) => {
              subject.next(res);
              subject.complete();
            },
            error: (err) => {
              subject.error(err);
              subject.complete();
            },
          });
      })
      .catch((e) => {
        this._snackbarService.open("failure", "Failed to update token");
        subject.error(e);
        subject.complete();
      });

    return subject.asObservable();
  }
}
