<div
  class="generic-search-bar gap-4 flex-row align-center"
  [ngClass]="{ 'is-used': customSearch.value, small: isSmall }"
>
  <generic-icon
    class="search-icon"
    [iconConfiguration]="{
    renderEntity:'search',
    renderType:'symbol',
  }"
  />
  <div class="search-input-wrap">
    <label>{{ title }}</label>
    <input
      #customSearch
      data-cy="generic-search-input"
      [(ngModel)]="filterTerm"
      spellcheck="false"
    />
  </div>
  @if (filterTerm && !searching) {
  <button
    mat-icon-button
    (click)="clear()"
    class="search-clear density-high"
    [style.overflow]="'hidden'"
    matTooltip="Clear"
  >
    <generic-icon
      [iconConfiguration]="{
        renderEntity: 'backspace',
        renderType: 'symbol',
        symbolOptSize: 'sm'
      }"
    />
  </button>
  } @if ( searching ) {
  <mat-spinner
    style="flex-shrink: 0"
    data-cy="generic-search-loader"
    mode="indeterminate"
    diameter="24"
    strokeWidth="2"
  />
  }
</div>
