import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "stringToArray",
})
export class StringToArrayPipe implements PipeTransform {
  transform(value: string): Array<string> {
    try {
      return JSON.parse(value);
    } catch {
      return [];
    }
  }
}
