<div class="flex-column flex-grow">
  <div class="flex-column flex-grow table-wrapper gap-8">
    <app-table-card-header
      [title]="tableConfiguration.title"
      [counter]="
        tableConfiguration.includeCounter
          ? this.dataSource.filteredData.length
          : undefined
      "
      [includeDialogHandler]="tableConfiguration.includeCreateButton"
      [includeSearchBar]="tableConfiguration.includeSearchBar"
      (createEvent)="this.createEvent.emit()"
      (searchTermChange)="filterData($event)"
    />

    <div class="flex-grow unit-relative">
      <div class="table-content">
        <div class="flex-column unit-relative">
          <table
            data-wizard-target="table-interaction"
            [dataSource]="dataSource"
            class="unit-relative"
            aria-describedby="A generic table"
            mat-table
          >
            @for (column of tableConfiguration.columns; track column) {
            <ng-container [matColumnDef]="column.name">
              <th mat-header-cell *matHeaderCellDef>
                {{ column.name | titlecase }}
              </th>
              <td mat-cell *matCellDef="let element">
                @if (!column.special) {
                {{ element[column.name] ? element[column.name] : "-" }}
                } @if (column.special && tableSpecialColumn) {
                <ng-container
                  [ngTemplateOutlet]="tableSpecialColumn"
                  [ngTemplateOutletContext]="
                    $any({
                      columnCellData: element[column.name]
                    })
                  "
                />
                }
              </td>
            </ng-container>
            }
            <ng-container matColumnDef="actions">
              <th *matHeaderCellDef mat-header-cell></th>
              <td class="actions" *matCellDef="let element" mat-cell>
                @if (tableActions) {
                <div>
                  <ng-container
                    [ngTemplateOutlet]="tableActions"
                    [ngTemplateOutletContext]="$any({ tableElement: element })"
                  />
                </div>
                }
              </td>
            </ng-container>
            <tr
              *matHeaderRowDef="tableColumns; sticky: true"
              mat-header-row
            ></tr>
            <tr *matRowDef="let row; columns: tableColumns" mat-row></tr>
          </table>
          @if (tableLoading) {
          <div
            class="local-table-loader"
            relativeLoader
            [loading]="tableLoading"
          >
            <div></div>
          </div>
          }
        </div>
        @if (this.dataSource.filteredData.length === 0) {
        <div class="table-empty-state flex-row">
          <span>No data found...</span>
        </div>
        }
      </div>
    </div>
  </div>
  <mat-paginator (page)="paginatiorInteraction($event)" class="fixed">
  </mat-paginator>
</div>
