import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from "@angular/core";
import { fromEvent, Subject, takeUntil } from "rxjs";

/**The user can scroll through the overflowing entity using the cursor, it mimicks the behaviour found on mobile devices.*/
@Directive({
  selector: "[dragScroll]",
})
export class DragScrollDirective implements OnDestroy {
  @Input() enableDrag = false;

  private scrollFinish$: Subject<boolean> = new Subject();

  constructor(private renderer: Renderer2, private el: ElementRef) {}
  ngOnDestroy(): void {
    this.scrollFinish$.next(true);
    this.scrollFinish$.complete();
  }

  @HostListener("mousedown", ["$event"]) onDown($event: MouseEvent): void {
    if (!this.enableDrag || $event.button !== 0) {
      return;
    }
    this.renderer.setStyle(this.el.nativeElement, "cursor", "grabbing");
    this.renderer.setStyle(this.el.nativeElement, "user-select", "none");
    fromEvent<MouseEvent>(document, "mousemove")
      .pipe(takeUntil(this.scrollFinish$))
      .subscribe((mouseMove: MouseEvent) => {
        this.el.nativeElement.scrollTop += mouseMove.movementY * -1;
        this.el.nativeElement.scrollLeft += mouseMove.movementX * -1;
      });
  }

  @HostListener("mouseleave")
  @HostListener("mouseup")
  onLeave(): void {
    if (!this.enableDrag) {
      return;
    }
    this.finishScroll();
  }

  private finishScroll(): void {
    this.scrollFinish$.next(true);
    this.renderer.removeStyle(this.el.nativeElement, "cursor");
    this.renderer.removeStyle(this.el.nativeElement, "user-select");
  }
}
