<div
  [attr.data-cy]="'task-' + data.attribute"
  class="wrap flex-row gap-8 align-center justify-center"
>
  <mat-icon>{{ data.icon }}</mat-icon>
  <span class="flex-grow">{{ data.content }}</span>
  @if (data.action) {
  <button mat-button (click)="this.snackBarRef.dismiss()">
    {{ data.action }}
  </button>
  }
</div>
