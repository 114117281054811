import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { UserDataResolver } from "./auth/user-data.resolver";

export const routes: Route[] = [
  {
    path: "invitations",
    loadChildren: () =>
      import("../app/modules/invitations/invitations.module").then(
        (m) => m.InvitationsModule
      ),
  },
  {
    path: "",
    resolve: { routeResolver: UserDataResolver },
    loadChildren: () =>
    import("./core/layout/layout.module").then(
      (m) => m.LayoutModule
    ),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
