import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { GenericSnackbarComponent } from "../component/generic-snackbar/generic-snackbar.component";
import { snackIcon } from "../utilities/types";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  constructor(private _snackbar: MatSnackBar) {}
  open(
    type: "success" | "failure" | "info",
    message: string,
    action?: string
  ): void {
    this._snackbar.openFromComponent(GenericSnackbarComponent, {
      data: {
        content: message,
        icon: snackIcon[type],
        action: action,
        attribute: type,
      },
      duration: action ? undefined : 4000,
      horizontalPosition: "center",
      verticalPosition: "top",

      panelClass: ["snackbar", `snackbar-${type}`],
    });
  }
}

