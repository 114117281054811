<h2 mat-dialog-title>Delete {{ data.type }}</h2>
<form
  class="flex-column gap-8 density-mid"
  (ngSubmit)="onSubmit()"
  autocomplete="off"
>
  <mat-dialog-content>
    <p>
      {{ data.type }} name: <b>{{ data.name }}</b>
    </p>
    @if (data.description) {
    <p class="special-display">
      {{ data.description }}
    </p>
    }
    <p>
      Are you sure that you want to delete this
      <b>{{ data.type | lowercase }}</b
      >?
    </p>
    <mat-form-field>
      <mat-label>{{ data.type | titlecase }} name: </mat-label>
      <input
        data-cy="validate-input"
        matInput
        [formControl]="nameMatch"
        placeholder="{{ data.name }}"
      />
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-stroked-button
      color="primary"
      type="button"
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      color="warn"
      type="submit"
      [disabled]="nameMatch.invalid"
      data-cy="submit-form"
    >
      Delete {{ data.type }}
    </button>
  </mat-dialog-actions>
</form>
