import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { environment } from "../../environments/environment";
import { TUserData } from "./utilities/types";

@Injectable()
export class AuthService {
  private isInitialized = false;

  constructor(private keycloak: KeycloakService, private router: Router) {}

  initializeKeycloak(): Promise<boolean> {
    if (this.isInitialized) {
      return Promise.resolve(true);
    }

    this.isInitialized = true;

    return this.keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: "horizon-view",
        clientId: "platform",
      },
      initOptions: {
        onLoad: "check-sso",
        // checkLoginIframe: false,
        // silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
    });
  }

  async getUserData(): Promise<TUserData | undefined> {
    const loggedIn = this.keycloak.isLoggedIn();
    if (!loggedIn) return;
    return await this.keycloak.loadUserProfile();
  }
}
