import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: "empty-or-error-state",
  templateUrl: "./empty-or-error-state.component.html",
  styleUrls: ["./empty-or-error-state.component.scss"],
})
export class EmptyOrErrorStateComponent implements OnChanges {
  @Input() data: unknown = undefined;
  @Input() error: string | undefined = undefined;
  @Input() fallbackText = "No data currently...";

  ngOnChanges() {
    this.fallbackText = this.error ?? "No data currently...";
  }
}
