import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { WizardService } from "./services/wizard.service";
import { IWizardButtonCore, IWizardStep } from "./utilities/interfaces";
import { GuideFrameService } from "../../services/guide-frame.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "wizard-overlay",
  templateUrl: "./wizard-overlay.component.html",
  styleUrls: ["./wizard-overlay.component.scss"],
})
export class WizardOverlayComponent implements OnInit, OnDestroy {
  currentStep: IWizardStep | undefined;

  componentIsDestroyed: Subject<boolean> = new Subject();

  constructor(
    private _wizardService: WizardService,
    private _guideFrameService: GuideFrameService,
    private cdRef: ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.complete();
  }
  ngOnInit(): void {
    this._wizardService.wizardStep$
      .pipe(takeUntil(this.componentIsDestroyed))
      .subscribe((step: IWizardStep | undefined) => {
        this.currentStep = step;
        this.cdRef.detectChanges();
      });
  }

  openHelpWindow(): void {
    this._guideFrameService.activateHelpGuide(environment.documentationUrl);
  }

  closeWizard(): void {
    this._wizardService.deactivateWizard();
  }

  processButton(buttonClick: IWizardButtonCore): void {
    if (!buttonClick.dontRespond) {
      this._wizardService.wizardResponds();
    }
    if (buttonClick.routing) {
      this._wizardService.specialRouting(
        buttonClick.routing,
        buttonClick.jumpTo
      );
    } else if (buttonClick.closeDialog) {
      this._wizardService.specialDialog(buttonClick.jumpTo);
    } else if (buttonClick.jumpTo === -1) {
      this._wizardService.deactivateWizard();
    } else if (this._wizardService.getFallBackState()) {
      this._wizardService.setStep(
        buttonClick.fallbackStep ?? buttonClick.jumpTo
      );
    } else {
      this._wizardService.setStep(buttonClick.jumpTo);
    }
  }
}
