import { Directive, Self } from "@angular/core";
import { MatSelect } from "@angular/material/select";

@Directive({
  selector: "[noSpace]",
})
export class NoSpaceDirective {
  constructor(@Self() private select: MatSelect) {
    this.select._handleKeydown = (event: KeyboardEvent): void => {
      if (event.code === "Space") {
        return;
      }
      if (!this.select.disabled) {
        this.select.panelOpen
          ? (this.select as any)._handleOpenKeydown(event)
          : (this.select as any)._handleClosedKeydown(event);
      }
    };
  }
}
