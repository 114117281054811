import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TableCardHeaderComponent } from "./component/table-card-header/table-card-header.component";
import { SearchPipe } from "./pipes/search.pipe";
import { GenericDeleteDialogComponent } from "./component/generic-delete-dialog/generic-delete-dialog.component";
import { ChipsComponent } from "./component/chips-display/chips.component";
import { GenericSnackbarComponent } from "./component/generic-snackbar/generic-snackbar.component";
import { EmptyOrErrorStateComponent } from "./component/empty-or-error-state/empty-or-error-state.component";
import { RelativeLoaderDirective } from "./directives/relative-loader.directive";
import { GenericConfirmDialogComponent } from "./component/generic-confirm-dialog/generic-confirm-dialog.component";
import { ExpansionMenuDirective } from "./directives/expansion-menu.directive";
import { FilteringPipe } from "./pipes/filtering.pipe";
import { TruncatePipePipe } from "./pipes/truncate-pipe.pipe";
import { TimestampPipe } from "./pipes/timestamp.pipe";
import { SaveContentDialogComponent } from "./component/save-content/save-content-dialog.component";
import { GenericSearchBarComponent } from "./component/generic-search/generic-search-bar.component";
import { MaterialImportsModule } from "./material-imports.module";
import { RevisionDropchoiceComponent } from "./component/revision-dropchoice/revision-dropchoice.component";
import { WizardOverlayComponent } from "src/app/modules/shared/component/wizard-overlay/wizard-overlay.component";
import { StringSplicePipe } from "./pipes/string-splice.pipe";
import { SafeUrlPipe } from "./pipes/safe-url.pipe";
import { DragScrollDirective } from "./directives/drag-scroll.directive";
import { GenericContextLoaderComponent } from "./component/generic-context-loader/generic-context-loader.component";
import { StringToArrayPipe } from "./pipes/string-to-array.pipe";
import { GenericIconComponent } from "./component/generic-icon/generic-icon.component";
import { GenericTableComponent } from "./component/generic-table/generic-table.component";
import { NoSpaceDirective } from "./directives/no-space.directive";
import { DisplayFormatDirective } from "./directives/display-format.directive";
import { DisplayBindDirective } from "./directives/display-bind.directive";
import { FrameWindowComponent } from "./component/frame-window/frame-window.component";
import { GenericCardComponent } from "./component/generic-card/generic-card.component";
import { RevisionsComponent } from "./component/revisions-display/revisions.component";
@NgModule({
  declarations: [
    FilteringPipe,
    TruncatePipePipe,
    SearchPipe,
    StringSplicePipe,
    TimestampPipe,
    SafeUrlPipe,
    StringToArrayPipe,
    RevisionsComponent,
    TableCardHeaderComponent,
    GenericDeleteDialogComponent,
    GenericSnackbarComponent,
    EmptyOrErrorStateComponent,
    GenericConfirmDialogComponent,
    GenericTableComponent,
    SaveContentDialogComponent,
    GenericSearchBarComponent,
    RevisionDropchoiceComponent,
    WizardOverlayComponent,
    GenericContextLoaderComponent,
    GenericIconComponent,
    FrameWindowComponent,
    ChipsComponent,
    FrameWindowComponent,
    GenericCardComponent,
    RelativeLoaderDirective,
    ExpansionMenuDirective,
    DragScrollDirective,
    NoSpaceDirective,
    DisplayFormatDirective,
    DisplayBindDirective,
  ],
  imports: [CommonModule, RouterModule, MaterialImportsModule],
  exports: [
    MaterialImportsModule,
    TableCardHeaderComponent,
    RevisionDropchoiceComponent,
    EmptyOrErrorStateComponent,
    GenericSearchBarComponent,
    GenericContextLoaderComponent,
    GenericIconComponent,
    GenericTableComponent,
    ChipsComponent,
    FrameWindowComponent,
    GenericCardComponent,
    RelativeLoaderDirective,
    ExpansionMenuDirective,
    DisplayFormatDirective,
    DisplayBindDirective,
    DragScrollDirective,
    NoSpaceDirective,
    FilteringPipe,
    TruncatePipePipe,
    SearchPipe,
    TimestampPipe,
    StringSplicePipe,
    SafeUrlPipe,
    StringToArrayPipe,
  ],
})
export class SharedModule {}
