import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "stringSplice",
})
export class StringSplicePipe implements PipeTransform {
  transform(value: string, separator: string, returnMember: number): string {
    return value.split(separator)[returnMember].trim();
  }
}
