<div class="dialog-wrap">
  <div relativeLoader [loading]="isLoading">
    <div data-cy="revision-dialog" class="flex-column gap-8 flex-grow parent">
      <div>
        <h2 mat-dialog-title [style.margin-bottom.px]="4">
          {{ revisionData.title | titlecase }} history
        </h2>
        @if (revisionData.subtitle) {
        <span class="text-color-muted">
          {{ revisionData.subtitle }}
        </span>
        }
      </div>
      <div
        class="flex-row justify-space-between align-center flex-wrap flow-auto"
      >
        <h4
          [style.color]="'var(--hv-primary-font-color)'"
          [style.margin-bottom.px]="0"
        >
          Select revision
        </h4>
        <div class="align-center flex-row gap-8">
          <generic-search-bar
            ng-displayBind
            [(filterTerm)]="filterTerm"
            [title]="'Search tags'"
          />
          <button mat-icon-button matTooltip="Sort" (click)="sortRevisions()">
            <generic-icon
              [iconConfiguration]="{
                renderEntity: 'swap_vert',
                renderType: 'symbol'
              }"
            />
          </button>
        </div>
      </div>
      @if (!isLoading) {
      <mat-dialog-content>
        <div data-cy="revision-list">
          <mat-radio-group
            color="accent"
            class="flex-column gap-8"
            [formControl]="revisionControl"
          >
            @for (revisionItem of filteredRevisions; track
            revisionItem.timestamp;let timestampIndex = $index) {
            <div
              class="revision-item flex-row"
              [ngClass]="{
                'active-item': revisionItem.revision === revisionControl.value
              }"
              data-cy="revision-data-item"
              (click)="setRevision(revisionItem.revision)"
            >
              <div class="flex-column">
                <span class="title">
                  {{ revisionItem.timestamp | timestamp }}
                </span>
                <span class="subtitle">
                  {{ revisionItem.messages ?? "" }}
                </span>
                <mat-chip-listbox class="tags-chip" color="primary" multiple>
                  @for (tag of revisionItem.tags; track tag) {
                  <mat-chip disableRipple="true" highlighted="false">
                    {{ tag }}
                  </mat-chip>
                  }
                </mat-chip-listbox>
              </div>
              <mat-radio-button [value]="revisionItem.revision" />
            </div>
            }
          </mat-radio-group>
        </div>
      </mat-dialog-content>
      }

      <mat-dialog-actions>
        <button
          mat-stroked-button
          data-cy="cancel-revision"
          (click)="onClose()"
          color="primary"
          type="button"
        >
          Cancel
        </button>
        @if (role !== 'VIEWER') {
        <button
          data-cy="load-revision"
          (click)="onSubmit()"
          [disabled]="revisionControl.invalid || revisionResponse.length === 0"
          mat-raised-button
          color="accent"
        >
          Apply
        </button>
        }
      </mat-dialog-actions>
    </div>
  </div>
</div>
