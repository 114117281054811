import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "revision-dropchoice",
  templateUrl: "./revision-dropchoice.component.html",
  styleUrls: ["./revision-dropchoice.component.scss"],
})
export class RevisionDropchoiceComponent {
  @Output() handleRevision = new EventEmitter<boolean>();
}
