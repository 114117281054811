import { Injectable } from "@angular/core";
import { RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { TUserData } from "./utilities/types";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class UserDataResolver  {
  constructor(
    private keycloak: KeycloakService,
    private _authService: AuthService
  ) {}
  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<TUserData | undefined> {
    return this._authService.getUserData();
  }
}
